import React from "react";
import Card from "./Card";
import card1 from "../../../assets/images/map-image/card-1.svg";
import card2 from "../../../assets/images/map-image/card-2.svg";
import card3 from "../../../assets/images/map-image/card-3.svg";
import card4 from "../../../assets/images/map-image/card-4.svg";
import card5 from "../../../assets/images/map-image/card-5.svg";
import card6 from "../../../assets/images/map-image/card-6.svg";
import card7 from "../../../assets/images/map-image/card-7.svg";
import card8 from "../../../assets/images/map-image/card-8.svg";
import card9 from "../../../assets/images/map-image/card-9.svg";

const CardsHolder = ({ elements, autoImage }) => {
  if (autoImage) {
    let cards = [card1, card2, card3, card4, card5, card6, card7, card8, card9];

    elements.map((element, index) => {
      element.image = cards[index];
      return element;
    });
  }

  return (
    <div className={"container details-card-holder"}>
      {elements.map((element, index) => {
        return <Card element={element} key={index} />;
      })}
    </div>
  );
};

export default CardsHolder;
