import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back from "../assets/images/map-image/back-button.svg";
import background from "../assets/images/map-image/not-found-background.svg";
import ScrollToTop from "../utils/ScrollToTop";
import congrats from "../assets/images/map-image/modal-icons/congrats.svg";

const ThankYou = () => {
  const { t } = useTranslation();

  return (
    <section
      className="body-wrap"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <ScrollToTop />
      <img
        className="not-found-background"
        src={background}
        width={20}
        height={20}
        alt={"background"}
      />
      <div className="container">
        <div className="hero-inner">
          <div
            className="hero-copy"
            style={{
              flex: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <img
              src={congrats}
              alt="modal"
              style={{ paddingHorizontal: "auto" }}
              width={"60x"}
              height={"60px"}
            />
            <p className="hero-secondary-title-blue hero-secondary-title">
              {t("thanks_title")}
            </p>
            <p className="home-body">{t("thanks_description")}</p>
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <Link to="/" className="back-button-holder">
              <img
                className="not-found-back-button"
                src={back}
                width={20}
                height={20}
                alt="back"
              />
              <p className="not-found-back-text">{t("back_to_home")}</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
