import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import Subscribe from "../../components/layout/partials/Subscribe";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import helpdesk from "../../assets/images/about-images/helpdesk.png";
import { useTranslation } from "react-i18next";
import {
  blockchainDetails,
  blockchainDevelopmentProcess,
  helpdeskDetails,
} from "../../assets/static/card-details";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import ScrollToTop from "../../utils/ScrollToTop";

const HelpDeskService = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("helpdesk")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("helpdesk_title") + " "}
              <span className="hero-secondary-title-blue">
                {t("helpdesk_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">{t("helpdesk_body")}</p>
          </div>
        </div>

        <div>
          <CardsHolder elements={blockchainDetails} />
        </div>

        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("helpdesk_paragraph_1_title")}
          </p>
          <p className="hero-secondary-body">
            {t("helpdesk_paragraph_1_body")}
          </p>
        </div>

        <CardsHolder elements={blockchainDevelopmentProcess} autoImage={true} />
      </div>

      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default HelpDeskService;
