import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";
import HeaderNav from "./partials/HeaderNav";
import LanguageToggle from "./partials/LanguageToggle";
import { routes } from "../../assets/static/router";
import { setMenu } from "../../actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(true);
  const dispatch = useDispatch();
  const nav = useRef(null);
  const hamburger = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    let menuIndex = routes.filter(
      (e) => e.name === location.pathname.split("/")[1]
    )[0]?.index;
    if (menuIndex) {
      ul(menuIndex);
    }

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  }, []);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const ul = (index) => {
    let selectedMenu = routes.filter((route) => route.index === index)[0];
    setMenu(selectedMenu.index, dispatch);
    var underlines = document.querySelectorAll(".underline");

    for (var i = 0; i < underlines.length; i++) {
      underlines[i].style.transform =
        "translate3d(" +
        index * (nav.current.offsetWidth / routes.length) +
        "px,0,0)";
    }
  };

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo height={50} width={50} />
          <>
            <button
              ref={hamburger}
              className="header-nav-toggle"
              onClick={isActive ? closeMenu : openMenu}
            >
              <span className="screen-reader">{t("menu")}</span>
              <span className="hamburger">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <nav ref={nav} className={"header-nav black"}>
              <div className="header-nav-inner">
                <HeaderNav navPosition={navPosition} onClick={ul} />
              </div>
            </nav>
          </>
          {/* <div className="language-nav-holder">
            <LanguageToggle />
          </div> */}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
