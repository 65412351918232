import React from "react";
import "html5-device-mockups/dist/device-mockups.min.css";

const renderType = (type) => {
  switch (type) {
    case "ios":
      return {
        margin: "-40px",
        name: "iPhone7",
        width: "220px",
      };

    case "android":
      return {
        margin: "-40px",
        name: "Pixel",
        width: "220px",
      };

    case "mac":
      return {
        margin: "0px",
        name: "Macbook2015",
        width: "480px",
      };
    case "desktop":
      return {
        margin: "0px",
        name: "SurfaceStudio",
        width: "420px",
      };
    case "tablet":
      return {
        margin: "-40px",
        name: "iPadPro",
        width: "230px",
      };
    default:
      return {
        margin: "0px",
        name: "",
        width: "450px",
      };
  }
};

const ProjectSection = ({ title, body, image, side, type, isDevice }) => {
  let device = renderType(type);

  return (
    <div
      className={
        side === "left"
          ? "container project-section-background-left"
          : "container project-section-background-right"
      }
    >
      <div className="project-section-holder">
        {side === "left" && (
          <div className="project-section-image-holder">
            <div className={"project-section-image-left"}>
              <div
                className="device-holder"
                style={{ marginTop: device.margin }}
              >
                <div
                  className="device-wrapper"
                  style={{ maxWidth: device.width }}
                >
                  <div
                    // className="device"
                    data-device={device.name}
                    data-orientation="portrait"
                    data-color="black"
                  >
                    <div className="screen">
                      <img
                        src={image}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="device"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={"project-section-body"}>
          <p className="project-section-title">{title}</p>
          <p className="project-section-description">{body}</p>
        </div>
        {side === "right" && (
          <div className="project-section-image-holder">
            <div
              className={"project-section-image-right"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isDevice ? (
                <img
                  src={image}
                  style={{
                    maxHeight: "120%",
                    maxWidth: "100%",
                  }}
                  alt="device"
                />
              ) : (
                <div
                  className="device-holder"
                  style={{ marginTop: device.margin }}
                >
                  <div
                    className="device-wrapper"
                    style={{ maxWidth: device.width }}
                  >
                    <div
                      // className="device"
                      data-device={device.name}
                      data-orientation="portrait"
                      data-color="black"
                    >
                      <div className="screen">
                        <img
                          src={image}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="device"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSection;
