import React, { useCallback } from "react";
import shadow from "../assets/images/background/shadow.svg";
import shadow2 from "../assets/images/background/shadow2.svg";
import head from "../assets/images/background/head.svg";
import PortfolioLogos from "../components/layout/PortfolioLogos";
import Services from "../components/layout/Services";
import { useTranslation } from "react-i18next";
import { projects } from "../assets/static/projects";
import { services } from "../assets/static/services";
import { examples } from "../assets/static/examples";
import Examples from "../components/layout/Examples";
import ContactUs from "../components/layout/ContactUs";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Carousel from "../components/layout/Carousel";
import Modal from "react-modal";
import ActionModal from "../components/layout/partials/ActionModal";
import RequestBriefModal from "../components/layout/partials/RequestBriefModal";
import { sendMessage } from "../actions";
import { Link } from "react-router-dom";
import Logo from "../components/layout/partials/Logo";
import congrats from "../assets/images/hero-header-2.svg";
import { particlesConfig } from "../utils/configParticles";
import ScrollToTop from "../utils/ScrollToTop";

const Home = (props) => {
  const { t } = useTranslation();
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [actionModalIsOpen, setActionModalIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function sendActionMessage(email, fullName, phoneNumber, message) {
    sendMessage(fullName, email, phoneNumber, message)
      .then((res) => {
        setModalContent({
          type: "congrats",
          title: t("message_sent_successfully"),
          body: t("message_sent_successfully_description"),
        });
        closeModal();
        setActionModalIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: "block",
          title: t("message_sent_error"),
          body: t("message_sent_error_description"),
        });
        closeModal();
        setActionModalIsOpen(true);
      });
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeActionModal() {
    setActionModalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
    },
  };

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "500px",
          zIndex: -100,
        }}
      >
        <Particles
          id="tsparticles"
          canvasClassName="particle-canvas"
          init={particlesInit}
          loaded={particlesLoaded}
          options={particlesConfig}
        />
      </div>
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy info-box">
            <p className="home-title">{t("software_development_services")}</p>
            <p className="home-body">
              {t("software_development_services_description")}
            </p>
            <div className="hero-cta">
              <a className="button button-primary" href="#" onClick={openModal}>
                {t("request_a_brief")}
              </a>
              <Link to="/about" className="button">
                {t("learn_more")}
              </Link>
            </div>
          </div>
          <div className="hero-copy image-box hero-images-holder">
            <img className="hero-image-1" src={shadow} />
            <img className="hero-image-2" src={shadow2} />
            <div className="info-box-3"></div>

            <div className="info-box-1">
              <p className="info-box-1-text">{t("head_quote")}</p>
            </div>
            <div className="info-box-2">
              {/* <img
                src={congrats}
                alt="modal"
                className={"modal-image"}
                style={{
                  width: "70px",
                  height: "70px",
                  transform: "rotate(10deg)",
                }}
              /> */}
              <Logo height={60} width={60} />
            </div>
            <img className="hero-head" src={head} />
          </div>
        </div>
        {/* <PortfolioLogos elements={projects} /> */}
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("welcome_to") + " "}
            <span className="hero-secondary-title-blue">
              {t("quartz_technologies")}
            </span>
          </p>
          <p className="hero-secondary-body">
            {t("welcome_to_quartz_technologies_description")}
          </p>
        </div>
        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">{t("explore_our_services")}</p>
            <p className="hero-secondary-body">
              {t("explore_our_services_body")}
            </p>
          </div>
          <Services elements={services} />
        </div>
        {/* <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("examples_of_software_development")}
            </p>
            <p className="hero-secondary-body">
              {t("examples_of_software_development_body")}
            </p>
          </div>
          <Examples elements={examples} />
        </div> */}

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">{t("contact_us")}</p>
            <p className="hero-secondary-body">{t("contact_us_body")}</p>
          </div>
          <ContactUs />
        </div>

        {/* <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">{t("our_clients")}</p>
            <p className="hero-secondary-body">{t("our_clients_body")}</p>
          </div>
        </div>
        <Carousel /> */}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <RequestBriefModal
          onCancel={closeModal}
          onSuccess={sendActionMessage}
          isCancellable={false}
        />
      </Modal>
      <Modal
        isOpen={actionModalIsOpen}
        onRequestClose={closeActionModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeActionModal}
          onSuccess={() => {
            console.log("Ok");
            props.history.push("/thanks");
          }}
          isCancellable={false}
        />
      </Modal>
    </section>
  );
};

export default Home;
