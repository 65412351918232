import React, { useState } from "react";
import {
  SiHtml5,
  SiJavascript,
  SiCss3,
  SiMysql,
  SiPostgresql,
  SiSpringboot,
  SiAngular,
} from "react-icons/si";
import { FaReact, FaJava, FaSwift } from "react-icons/fa";

const TechnologiesLogos = ({ elements }) => {
  let [selected, setSelected] = useState(undefined);

  const renderLogo = (logo) => {
    switch (logo) {
      case "html5":
        return (
          <SiHtml5
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "css3":
        return (
          <SiCss3
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "javascript":
        return (
          <SiJavascript
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "react":
        return (
          <FaReact
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "angular":
        return (
          <SiAngular
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "java":
        return (
          <FaJava
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "swift":
        return (
          <FaSwift
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "mysql":
        return (
          <SiMysql
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "postgres":
        return (
          <SiPostgresql
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      case "spring":
        return (
          <SiSpringboot
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
      default:
        return (
          <SiHtml5
            size={64}
            color={selected === logo ? "#735DFE" : "#B1B1B1"}
            style={{ margin: "30px" }}
            onMouseEnter={() => {
              setSelected(logo);
            }}
            onMouseLeave={() => {
              setSelected(undefined);
            }}
          />
        );
    }
  };

  return (
    <div className={"container technologies-holder"}>
      {elements.map((element, index) => {
        return (
          <div className={"logo-holder"} key={index}>
            {renderLogo(element.toLowerCase())}
          </div>
        );
      })}
    </div>
  );
};

export default TechnologiesLogos;
