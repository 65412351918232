import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import Subscribe from "../../components/layout/partials/Subscribe";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import uiux from "../../assets/images/about-images/testing.png";
import uiux2 from "../../assets/images/about-images/ui-ux-scaled.jpg";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import { uiuxDetails, uiuxDetails2 } from "../../assets/static/card-details";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const UIUXService = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("uiux")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("uiux_title")}
              <span className="hero-secondary-title-blue">
                {" " + t("uiux_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">{t("uiux_body")}</p>
          </div>
        </div>
      </div>

      <ServiceAboutHolder
        title={t("uiux_paragraph_1_title")}
        body={t("uiux_paragraph_1_body")}
        image={uiux2}
        side={"left"}
      />

      <div>
        <div className="container hero-secondary">
          <p className="hero-secondary-title">
            {t("uiux_paragraph_2_title")}
            <span className="hero-secondary-title-blue">
              {" " + t("uiux_paragraph_2_title_blue")}
            </span>{" "}
          </p>
        </div>
      </div>
      <CardsHolder elements={uiuxDetails} />

      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("uiux_paragraph_3_title")}</p>
          <p className="hero-secondary-body">{t("uiux_paragraph_3_body")}</p>
        </div>
      </div>
      <CardsHolder elements={uiuxDetails2} autoImage={true} />

      <ServiceAboutHolder
        title={t("uiux_paragraph_4_title")}
        body={t("uiux_paragraph_4_body")}
        image={uiux}
        side={"right"}
      />

      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default UIUXService;
