import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { routes } from "../../../assets/static/router";
import { useTranslation } from "react-i18next";

const FooterNav = ({ className, ...props }) => {
  const { t } = useTranslation();

  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        {routes.map((route, index) => (
          <li key={index}>
            <Link to={route.path}>{t(route.name)}</Link>
          </li>
        ))}
        <li key={10}>
          <Link to={"/privacy-policy"}>{t("privacy_policy")}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
