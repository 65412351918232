import React from "react";
import classNames from "classnames";
import Image from "../../elements/Image";
import facebook from "../../../assets/images/social/facebook.svg";
import linkedin from "../../../assets/images/social/linkedin.svg";
import instagram from "../../../assets/images/social/instagram.svg";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li key="facebook">
          <a href="https://facebook.com/">
            <Image src={facebook} width={24} height={24} />
          </a>
        </li>
        <li key="linkedin">
          <a href="https://twitter.com/">
            <Image src={linkedin} width={24} height={24} />
          </a>
        </li>
        <li key="instagram">
          <a href="https://google.com/">
            <Image src={instagram} width={24} height={24} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
