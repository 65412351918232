import ios from "../../assets/images/services-logos/ios-app-development.svg";
import android from "../../assets/images/services-logos/android-app-development.svg";

export const webDetails = [
  {
    title: "Business Analysis",
    description:
      "We provide services that will allow delivering the solution to bring sustainable business value and identify bottlenecks that could impact software efficiency",
  },
  {
    title: "UX/UI Design",
    description:
      "Our experts apply proven approaches to design, prototyping, product validation and offer UI design services to create applications that users enjoy",
  },
  {
    title: "Front-end Development",
    description:
      "We offer the service of front-end app development using modern frameworks such as React, Angular, Vue, Typescript, ES6, and more",
  },
  {
    title: "Back-end Development",
    description:
      "We provide a full suite of software development services for backend solutions that cater to businesses of any size",
  },
  {
    title: "Testing and Deployment",
    description:
      "We offer full-range testing services including manual testing techniques and automation testing",
  },
  {
    title: "Support and Maintenance",
    description:
      "We provide application support and maintenance services to ensure your business-critical systems are fast and available to use 24/7",
  },
];

export const desktopDetails = [
  {
    title: "Better hardware integration & performance",
  },
  {
    title: "Can work locally",
  },
  {
    title: "No security issues",
  },
];

export const mobileDetails = [
  {
    title: "Android app development",
    bullets: [
      "API Integration",
      "Location-based Services",
      "Payment Gateways",
      "Touch ID Security",
      "App analytics",
      "Push-notifications",
      "Multiple actions",
    ],
    image: android,
  },
  {
    title: "iOS app development",
    bullets: [
      "API Integration",
      "Location-based Services",
      "Payment Gateways",
      "Face ID Security",
      "App analytics",
      "Push-notifications",
      "Multiple actions",
    ],
    image: ios,
  },
];

export const devopsDetails = [
  {
    bullets: [
      "Cut unnecessary expenses in the most effective way",
      "Speed up time to market up to 50%",
      "Increase team productivity and deliver new functionality faster",
      "Get better analytical data",
      "Take risks under control",
      "Boost the growth of your business",
      "Gain your customers’ trust",
    ],
  },
];

export const seoDetails = [
  {
    bullets: [
      " DevOps Consultation",
      "CI/CD",
      "Cloud Expertise",
      "DevOps Test Automation",
      "DevOps Security",
      "Docker and Kubernetes Expertise",
    ],
  },
  {
    bullets: [
      "Cut unnecessary expenses in the most effective way",
      "Speed up time to market up to 50%",
      "Increase team productivity and deliver new functionality faster",
      "Get better analytical data",
      "Take risks under control",
      "Boost the growth of your business",
      "Gain your customers’ trust",
    ],
  },
];

export const embeddedDetails = [
  {
    title: "Single board and multi-board",
  },
  {
    title: "Multi-core and Multi-Processor",
  },
  {
    title: "Small Form Factor",
  },
  {
    title: "Flex-Rigid and Flexible PCBs Design",
  },
  {
    title: "Low Power and Wearable Design",
  },
  {
    title: "Design Validation and Compliance",
  },
  {
    title: "Reference Platform Development",
  },
  {
    title: "High-Speed Designs",
  },
  {
    title: "HDI PCB Design",
  },
];

export const testingDetails = [
  {
    detailedBullets: [
      {
        title: "Functional Testing",
        info: "Our QA and testing team can check every feature of your software or hardware product and will sure that function operates in compliance with the requirements specifications.",
      },
      {
        title: "Compatibility Testing",
        info: "Compatibility testing helps to ensure your software works properly on the different platforms and environments attracting more and more users. ",
      },
      {
        title: "Performance Testing",
        info: "Performance testing technique helps to determine that your product works at a high level and holds up under a given workload.",
      },
      {
        title: "Usability Testing",
        info: "Usability testing assures that end-users are satisfied with app interaction: every step is easy and understandable.",
      },
    ],
  },
  {
    detailedBullets: [
      {
        title: "Accessibility Testing",
        info: "Accessibility Testing will validate and ensure that the product system meets industry standards and business requirements. ",
      },
      {
        title: "Security Testing",
        info: "We will help you avoid recovery expenses by identifying all security flaws and vulnerabilities and protecting your product from hacker attacks.",
      },
      {
        title: "Regression Testing",
        info: "Our experienced QA specialists are able to inspect new product features don't affect the existing functionality of the application.",
      },
      {
        title: "Smoke Testing",
        info: "Smoke testing is needed to check the software or hardware product after a build and before a release. We assure that a product overall is testable and stable.",
      },
    ],
  },
];

export const uiuxDetails = [
  {
    detailedBullets: [
      {
        title: "Web & Desktop Design",
        info: "Our team creates a well-functioning and beautiful web design on different platfroms that attract, convert and retain more customers",
      },
      {
        title: "UX UI Mobile app design",
        info: "Our designers' goal is to create affordable user interfaces for iOS and Android that get positive feedback from end-users",
      },
    ],
  },
  {
    detailedBullets: [
      {
        title: "Data Visualization",
        info: "We provide visualization of data and insights with easy navigation and exploration based on specific requirements",
      },
      {
        title: "Pitch Deck Design",
        info: "We transform your project into a powerful tool to raise money and get investments. We’ve helped startups raise more than $1M",
      },
    ],
  },
];

export const uiuxDetails2 = [
  {
    title: "Research",
    bullets: ["Identify business requirements", "UX and UI research"],
  },
  {
    title: "Prototype",
    bullets: ["Raw scratches", "Interaction Design"],
  },
  {
    title: "Design",
    bullets: ["Graphical user interface design", "Delivery and Support "],
  },
];

export const helpdeskDetails = [
  {
    title:
      "Assembling , connection and configuration of PCs and office equipment",
  },
  {
    title: "Troubleshooting and fixes",
  },
  {
    title: "Controlling of performance and check-ups on systems and servers",
  },

  {
    title: "Installing updates and new software",
  },
];

export const journeyMap = [
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
  {
    description: "Lorem ipsum dolor samet",
  },
];

export const embeddedSystemsAndTechnologies = [
  {
    title: "Systems",
    bullets: [
      "nRF52 Series (nRF52810 / nRF52811 / nRF52832 / nRF52840)",
      "ESP32, ESP8266 ",
      "STM32, STM8 ",
      "AVR, Arduino ",
      "SBCs (Raspberry Pi / Rock Pi / NanoPC / BeagleBone and etc.) ",
    ],
  },
  {
    title: "Technologies",
    bullets: [
      "UART, SPI, I2C, I2S, PWM",
      "BLE, WiFi",
      "TCP, HTTP",
      "Linux",
      "FreeRTOS",
    ],
  },
];

export const embeddedApproach = [
  {
    title: "Design",
    bullets: [
      "Specifications",
      "Schematics",
      "Layout ",
      "Small batch production",
      "Testing & Fixing",
      "Mass Production",
    ],
  },
  {
    title: "Development",
    bullets: [
      "Processor selection",
      "Software Development",
      "Firmware Development",
      "Prototype",
      "Review & Testing ",
      "Deploy",
    ],
  },
  {
    title: "Industries",
    bullets: [
      "Wearable Devices",
      "Home Devices",
      "IoT",
      "Consumer Electronics Products",
      "Healthcare Devices",
      "Security Devices",
    ],
  },
];

export const desktopDevelopmentProcess = [
  {
    title: "Concept analysis",
    description:
      "Analyse what's being said about you or your competitors with our media monitoring tool. Easily find out if your brand is going viral with custom alerts.",
  },
  {
    title: "SRS document preparation",
    description:
      "Learn what's in a software requirements specification document (SRS), and get tips for writing effective SRS documents.",
  },
  {
    title: "UX UI design",
    description:
      "Our experts apply proven approaches to design, prototyping, product validation and offer UI design services to create applications that users enjoy",
  },
  {
    title: "Front-end & Back-end development",
    description:
      "We offer the service of desktop app development using modern frameworks such as React, Angular, Vue, Typescript, ES6, and more.",
  },
  {
    title: "Software Testing",
    description:
      "We offer full-range testing services including manual testing techniques and automation testing",
  },
  {
    title: "Deployment and Support",
    description: "Deploying your smart desktop to the markets.",
  },
];

export const blockchainDetails = [
  {
    detailedBullets: [
      {
        title: "Decentralized apps (dApps)",
        info: "dApps eliminate a single point of failure such as a hosting server, allowing you to prevent downtime and other operational restrictions.",
      },
      {
        title: "NFTs",
        info: "NFTs open up a wide range of business opportunities, from gaming and digital art collections to the music industry and real-estate tokenization.",
      },
      {
        title: "Crypto wallets",
        info: "We specialize in web and mobile cryptocurrency wallet development. This includes both custodial and non-custodial wallets.",
      },
    ],
  },
  {
    detailedBullets: [
      {
        title: "Blockchain Marketplaces",
        info: "Peer-to-peer platforms for transparent, fraud-free transactions between the involved parties.",
      },
      {
        title: "Smart Contracts",
        info: "Optimize the speed, efficiency, and security of your transaction-based processes with smart contracts on the blockchain. ",
      },
      {
        title: "Private blockchain networks",
        info: "Enhance the security and speed of your enterprise network while simplifying your data transactions.",
      },
    ],
  },
];

export const blockchainDevelopmentProcess = [
  { description: "Business & technical analysis" },
  { description: "Architecture and environment setup" },
  { description: "UX Design" },
  { description: "Proof of concept" },
  { description: "Development" },
  { description: "Deployment" },
];
