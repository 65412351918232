import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back from "../assets/images/map-image/back-button.svg";
import background from "../assets/images/map-image/not-found-background.svg";
import ScrollToTop from "../utils/ScrollToTop";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <img
        className="not-found-background"
        src={background}
        width={20}
        height={20}
        alt={"background"}
      />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <p className="not-found-title">{t("not_found_title")}</p>
            <p className="not-found-subtitle">{t("not_found_description")}</p>
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="not-found-404">404</p>
            <Link to="/" className="back-button-holder">
              <img
                className="not-found-back-button"
                src={back}
                width={20}
                height={20}
                alt="back"
              />
              <p className="not-found-back-text">{t("back_to_home")}</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
