import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import Subscribe from "../../components/layout/partials/Subscribe";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import seo from "../../assets/images/about-images/seo.png";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import { devopsDetails, seoDetails } from "../../assets/static/card-details";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const SEOService = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("seo")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("seo_paragraph_2_title") + " "}
            </p>
            <p className="hero-secondary-body">{t("seo_body")}</p>
          </div>
        </div>

        <br />

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("seo_paragraph_1_title") + " "}
            </p>
            <p className="hero-secondary-body">{t("seo_paragraph_1_body")}</p>
          </div>
        </div>
        <CardsHolder elements={seoDetails} />
      </div>

      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default SEOService;
