import React from "react";
import { services } from "../assets/static/services";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import ServicesList from "../components/layout/Services";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../utils/ScrollToTop";

const Services = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("services")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("services_paragraph_1_title")}{" "}
              <span className="hero-secondary-title-blue">
                {t("services_paragraph_1_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">
              {t("services_paragraph_1_body")}
            </p>
          </div>
          <div className="contact-holder">
            <ServicesList elements={services} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
