import React, { useEffect } from "react";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import { useTranslation } from "react-i18next";
import Subscribe from "../components/layout/partials/Subscribe";
import Examples from "../components/layout/Examples";
import { examples } from "../assets/static/examples";
import ProjectSection from "../components/layout/partials/ProjectSection";
import CardsHolder from "../components/layout/partials/CardsHolder";
import TechnologiesLogos from "../components/layout/partials/TechnologiesLogos";
import { useParams } from "react-router-dom";
import { getProjectById } from "../actions";
import NotFound from "./NotFound";
import ScrollToTop from "../utils/ScrollToTop";

const ProjectPage = (props) => {
  const { t } = useTranslation();
  let [project, setProject] = React.useState(undefined);
  let [error, setError] = React.useState(false);
  let { id } = useParams();

  useEffect(() => {
    getProjectById(id)
      .then((res) => {
        console.log(res.data);
        setProject(res.data);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }, [id]);

  console.log(id);
  return (
    <section className="body-wrap">
      <ScrollToTop />
      {error ? (
        <NotFound />
      ) : project === undefined ? (
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="container">
          <div className="hero-inner">
            <div className="hero-copy" style={{ flex: 3 }}>
              <NavigationHeader
                location={[t("home"), t("portfolio"), project.title]}
                logo={project.icon}
                description={t("portfolios_description")}
              />
            </div>
          </div>

          <div>
            <div className="hero-secondary">
              <p className="hero-secondary-title hero-secondary-title-blue">
                {project.title}
              </p>
              <p className="hero-secondary-body">{project.description}</p>
            </div>

            <div>
              <div className="hero-secondary-ticks-holder">
                <div className="hero-secondary-ticks-project">
                  {project.featuredTechnologies.map((item, index) => (
                    <div className="secondary-tick-holder" key={index}>
                      <li className="helpdesk-details-text" key={index}>
                        {item}
                      </li>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {project.sections.map((item, index) => (
              <div key={index}>
                <div className="hero-secondary" style={{ marginTop: "3%" }}>
                  <p className="hero-secondary-title">{item.title}</p>
                  <p className="hero-secondary-body">{item.description}</p>
                </div>
                <div>
                  {item.subsections.map((subsection, index) => (
                    <ProjectSection
                      title={subsection.title}
                      body={subsection.description}
                      image={subsection.image}
                      type={item.type}
                      side={index % 2 === 0 ? "left" : "right"}
                      key={index}
                      isDevice={subsection.device}
                    />
                  ))}
                </div>
                {index === 0 && project.journeyMap.size !== 0 && (
                  <JourneyMap
                    milestones={project.journeyMap.map((milestone) => {
                      return { title: milestone };
                    })}
                  />
                )}
                {index === 1 && project.technologies.size !== 0 && (
                  <Technologies technologies={project.technologies} />
                )}{" "}
              </div>
            ))}

            <div>
              <div className="hero-secondary">
                <Subscribe />
              </div>
            </div>
            {/* 
            <div>
              <div className="hero-secondary">
                <p className="hero-secondary-title">{t("our_works")}</p>
                <p className="hero-secondary-body">
                  {t("our_works_description")}
                </p>
              </div>
              <Examples elements={examples} />
            </div> */}
          </div>
        </div>
      )}
    </section>
  );
};

const JourneyMap = ({ milestones }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("journey_map")}</p>
          <p className="hero-secondary-body">{t("journey_map_body")}</p>
        </div>
        <CardsHolder elements={milestones} autoImage={true} />
      </div>
    </div>
  );
};

const Technologies = ({ technologies }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("technologies")}</p>
          <p className="hero-secondary-body">{t("technologies_body")}</p>
        </div>
        <TechnologiesLogos elements={technologies} />
      </div>
    </div>
  );
};

export default ProjectPage;
