import React from "react";
import map from "../../assets/images/map-image/map-image.svg";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import ActionModal from "./partials/ActionModal";
import { sendMessage } from "../../actions";

const ContactUs = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });

  function openModal() {
    sendMessage(fullName, email, phoneNumber, message)
      .then((res) => {
        setModalContent({
          type: "congrats",
          title: t("message_sent_successfully"),
          body: t("message_sent_successfully_description"),
        });
        setIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: "block",
          title: t("message_sent_error"),
          body: t("message_sent_error_description"),
        });
        setIsOpen(true);
      });
    // setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
    },
  };

  return (
    <div className="background">
      <div className="contact-container">
        <div className="contact-screen">
          <div className="screen-header">
            <div className="screen-header-left">
              <div className="screen-header-button close"></div>
              <div className="screen-header-button maximize"></div>
              <div className="screen-header-button minimize"></div>
            </div>
            <div className="screen-header-right">
              <div className="screen-header-ellipsis"></div>
              <div className="screen-header-ellipsis"></div>
              <div className="screen-header-ellipsis"></div>
            </div>
          </div>
          <div className="screen-body">
            <div className="screen-body-item left">
              <a href="/#">
                <img src={map} alt="map" />
              </a>
              <div className="app-contact">
                <span>{t("contact_us_phone")}</span>

                <span>{t("contact_us_email")}</span>
              </div>
            </div>
            <div className="screen-body-item">
              <div className="app-form">
                <div className="app-form-group">
                  <input
                    className="app-form-control"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder={t("contact_us_placeholder_name")}
                  />
                </div>
                <div className="app-form-group">
                  <input
                    className="app-form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("contact_us_placeholder_email")}
                  />
                </div>
                <div className="app-form-group">
                  <input
                    className="app-form-control"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder={t("contact_us_placeholder_phone")}
                  />
                </div>
                <div className="app-form-group message">
                  <textarea
                    className="app-form-control app-form-control-area"
                    value={message}
                    rows={5}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={t("contact_us_placeholder_message")}
                  />
                </div>
                <div className="app-form-group buttons">
                  <button className="app-form-button">{t("cancel")}</button>
                  <button
                    className="button button-wide-mobile button-sm button-send"
                    onClick={openModal}
                  >
                    {t("send")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeModal}
          onSuccess={closeModal}
          isCancellable={false}
        />
      </Modal>
    </div>
  );
};

export default ContactUs;
