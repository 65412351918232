import { MENU_CHANGE, LANGUAGE_CHANGE, back } from "../constants";
import axios from "axios";

export function setMenu(menu, dispatch) {
  return dispatch({
    type: MENU_CHANGE,
    payload: menu,
  });
}

export function setLanguage(language, dispatch) {
  return dispatch({
    type: LANGUAGE_CHANGE,
    payload: language,
  });
}

export function sendMessage(fullName, email, phoneNumber, message) {
  let link = `${back}/api/contacts`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  let data = {
    email: email,
    fullName: fullName,
    phoneNumber: phoneNumber,
    message: message,
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function addEmailToNewsletter(email) {
  let link = `${back}/api/newstellers`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  let data = {
    email: email,
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function addProject(data) {
  let link = `${back}/api/projects`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function getProjectById(id) {
  let link = `${back}/api/projects/${id}`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  return axios.get(link, { headers: myHeaders });
}

export function getProjectHeaders(language) {
  let link = `${back}/api/projects/headers?language=${language}&page=0&size=20`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  return axios.get(link, { headers: myHeaders });
}
