import React from "react";

const NavigationHeader = ({ location, title, description, edit, logo }) => {
  return (
    <div className={"container header-holder"}>
      <div className="details-holder">
        <div className="path-holder">
          {location.map((item, index) => {
            return (
              <div className="location" key={index}>
                <p
                  className={
                    index === location.length - 1
                      ? "path-name"
                      : "path-name bold"
                  }
                >
                  {item}
                </p>
                {index !== location.length - 1 && <div className="split" />}
              </div>
            );
          })}
        </div>
        {edit ? (
          edit
        ) : title ? (
          <p className="path-title">{title}</p>
        ) : (
          <img className={"header-logo"} src={logo} alt="logo" />
        )}
        <div className="path-split" />
        <p className="path-description">{description}</p>
      </div>
    </div>
  );
};

export default NavigationHeader;
