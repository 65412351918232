import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import Subscribe from "../../components/layout/partials/Subscribe";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import web from "../../assets/images/about-images/web.png";
import web1 from "../../assets/images/about-images/web1.png";
import web2 from "../../assets/images/about-images/web2.png";
import web3 from "../../assets/images/about-images/web3.png";
import web4 from "../../assets/images/about-images/web4.jpg";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import { webDetails } from "../../assets/static/card-details";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const WebDevelopmentService = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("web_app_development")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("web_app_development_title") + " "}
              <span className="hero-secondary-title-blue">
                {t("web_app_development_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">
              {t("web_app_development_body")}
            </p>
          </div>
        </div>

        <ServiceAboutHolder
          title={t("web_app_development_paragraph_2_title")}
          body={t("web_app_development_paragraph_2_body")}
          image={web1}
          side={"right"}
        />
        <ServiceAboutHolder
          title={t("web_app_development_paragraph_3_title")}
          body={t("web_app_development_paragraph_3_body")}
          image={web2}
          side={"left"}
        />
        <ServiceAboutHolder
          title={t("web_app_development_paragraph_4_title")}
          body={t("web_app_development_paragraph_4_body")}
          image={web3}
          side={"right"}
        />
        <ServiceAboutHolder
          title={t("web_app_development_paragraph_5_title")}
          body={t("web_app_development_paragraph_5_body")}
          image={web4}
          side={"left"}
        />
        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("web_app_development_paragraph_1_title") + " "}
            </p>
          </div>
        </div>
        <CardsHolder elements={webDetails} autoImage={true} />
      </div>

      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default WebDevelopmentService;
