import { MENU_CHANGE, LANGUAGE_CHANGE } from "../constants";
const initialState = {
  language: "en",
  menu: 0,
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_CHANGE:
      return {
        ...state,
        menu: action.payload,
      };
    case LANGUAGE_CHANGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
export default navigationReducer;
