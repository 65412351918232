import React from "react";
import Image from "../../elements/Image";

const Card = ({ element }) => {
  return (
    <div className={"detail-card"}>
      {element.image && (
        <Image
          className={"detail-image"}
          src={element.image}
          width={64}
          height={64}
        />
      )}
      {element.title && <p className="detail-title">{element.title}</p>}
      {element.bullets && (
        <ul>
          {element.bullets.map((item, index) => (
            <li className="detail-card-list-item" key={index}>
              {item}
            </li>
          ))}
        </ul>
      )}
      {element.description && (
        <p className="detail-card-description">{element.description}</p>
      )}
      {element.detailedBullets && (
        <ul>
          {element.detailedBullets.map((item, index) => (
            <div key={index}>
              <li className="detail-card-list-item-detailed" key={index}>
                {item.title}
              </li>
              <p className="detail-card-list-item-detail-info">{item.info}</p>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Card;
