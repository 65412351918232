import React from "react";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import { useTranslation } from "react-i18next";
import Subscribe from "../components/layout/partials/Subscribe";
import Examples from "../components/layout/Examples";
import { examples } from "../assets/static/examples";
import ProjectSection from "../components/layout/partials/ProjectSection";
import CardsHolder from "../components/layout/partials/CardsHolder";
import TechnologiesLogos from "../components/layout/partials/TechnologiesLogos";
import SubsectionEdit from "../components/layout/partials/SubsectionEdit";
import Modal from "react-modal";
import ActionModal from "../components/layout/partials/ActionModal";
import Fab from "@mui/material/Fab";
import { MdOutlineDone } from "react-icons/md";
import { addProject } from "../actions";

const services = [
  { value: "WEB", title: "Web Development" },
  { value: "MOBILE", title: "Mobile Development" },
  { value: "UIUX", title: "UI/UX Design" },
  { value: "TESTING", title: "Testing" },
  { value: "SEO", title: "SEO" },
  { value: "HELPDESK", title: "Helpdesk" },
  { value: "EMBEDDED", title: "Embedded" },
  { value: "DESKTOP", title: "Desktop App Development" },
];

const technologiesEnum = [
  { value: "HTML5", title: "HTML5" },
  { value: "CSS3", title: "CSS3" },
  { value: "ANGULAR", title: "ANGULAR" },
  { value: "REACT", title: "REACT" },
  { value: "POSTGRES", title: "POSTGRES" },
  { value: "MYSQL", title: "MYSQL" },
  { value: "JAVA", title: "JAVA" },
  { value: "SWIFT", title: "SWIFT" },
  { value: "JAVASCRIPT", title: "JAVASCRIPT" },
  { value: "EMBEDDED", title: "EMBEDDED" },
];

const AddProject = () => {
  const { t } = useTranslation();
  let [sections, setSections] = React.useState([]);
  let [currentSection, setCurrentSection] = React.useState({
    title: "",
    description: "",
    type: "ios",
    subsections: [],
  });

  let [journeyMap, setJourneyMap] = React.useState([]);
  let [technologies, setTechnologies] = React.useState([]);
  let [title, setTitle] = React.useState("");
  let [description, setDescription] = React.useState("");
  let [language, setLanguage] = React.useState("en");
  let [currentJourneyMap, setCurrentJourneyMap] = React.useState([]);
  let [icon, setIcon] = React.useState("");
  let [colorlessIcon, setColorlessIcon] = React.useState("");
  let [backgroundImage, setBackgrounImage] = React.useState("");
  let [selectedServices, setSelectedServices] = React.useState([]);
  let [isDevice, setIsDevice] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });

  function isDeviceChanged(e) {
    setIsDevice(e);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  }

  function openModal() {
    addProject({
      title,
      description,
      featuredTechnologies: selectedServices,
      sections,
      journeyMap: journeyMap.map((item) => item.title),
      technologies: technologies.map((item) => item.toUpperCase()),
      icon,
      language,
      backgroundImage,
      colorlessIcon,
      relatedServices: selectedServices,
    })
      .then((res) => {
        setModalContent({
          type: "congrats",
          title: t("message_sent_successfully"),
          body: t("message_sent_successfully_description"),
        });
        setIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: "block",
          title: t("message_sent_error"),
          body: e.message,
        });
        setIsOpen(true);
      });
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
    },
  };

  return (
    <section className="body-wrap">
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("portfolio"), title]}
              edit={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px",
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>Colored Icon: </p>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(file) => {
                        getBase64(file.target.files[0]).then((base64) => {
                          setIcon(base64);
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px",
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>Colorless Icon: </p>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(file) => {
                        getBase64(file.target.files[0]).then((base64) => {
                          setColorlessIcon(base64);
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px",
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>Background Image: </p>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(file) => {
                        getBase64(file.target.files[0]).then((base64) => {
                          setBackgrounImage(base64);
                        });
                      }}
                    />
                  </div>
                  <select
                    name="image-type"
                    id="type"
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                  >
                    <option value="en">en</option>
                    <option value="ru">ru</option>
                    <option value="az">az</option>
                  </select>

                  <div>
                    <div class="tagcloud01">
                      <ul>
                        {services.map((item) => (
                          <li
                            key={item.value}
                            onClick={() => {
                              if (selectedServices.includes(item.value)) {
                                setSelectedServices(
                                  selectedServices.filter(
                                    (i) => i !== item.value
                                  )
                                );
                              } else {
                                setSelectedServices([
                                  ...selectedServices,
                                  item.value,
                                ]);
                              }
                            }}
                          >
                            <a
                              className={
                                selectedServices.includes(item.value)
                                  ? "tag-selected"
                                  : ""
                              }
                            >
                              {item.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              }
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <input
              type={"text"}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={"Title"}
              className="hero-secondary-title add-project-input"
            />
            <input
              type={"text"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={"Description"}
              className="hero-secondary-body add-project-input"
            />
          </div>

          {sections.map((item, index) => (
            <div key={index}>
              <div className="hero-secondary" style={{ marginTop: "3%" }}>
                <p className="hero-secondary-title">{item.title}</p>
                <p className="hero-secondary-body">{item.description}</p>
              </div>

              <div>
                {item.subsections.map((subsection, index) => (
                  <ProjectSection
                    title={subsection.title}
                    body={subsection.description}
                    image={`data:image/png;base64, ${subsection.image}`}
                    type={item.type}
                    side={index % 2 === 0 ? "left" : "right"}
                    isDevice={subsection.device}
                    key={index}
                  />
                ))}
              </div>
            </div>
          ))}

          <div>
            <div className="hero-secondary" style={{ marginTop: "3%" }}>
              <input
                type="text"
                placeholder="Section Title"
                className="hero-secondary-title"
                value={currentSection.title}
                onChange={(e) =>
                  setCurrentSection({
                    ...currentSection,
                    title: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Section Body"
                className="hero-secondary-body"
                value={currentSection.description}
                onChange={(e) =>
                  setCurrentSection({
                    ...currentSection,
                    description: e.target.value,
                  })
                }
              />
              <select
                name="image-type"
                id="type"
                onChange={(e) => {
                  setCurrentSection({
                    ...currentSection,
                    type: e.target.value,
                  });
                }}
              >
                <option value="ios">iOS</option>
                <option value="android">Android</option>
                <option value="tablet">Tablet</option>
                <option value="mac">Mac</option>
                <option value="desktop">Desktop</option>
              </select>
            </div>

            <div>
              {currentSection.subsections.map((subsection, index) => (
                <ProjectSection
                  title={subsection.title}
                  body={subsection.description}
                  image={`data:image/png;base64, ${subsection.image}`}
                  type={currentSection.type}
                  side={index % 2 === 0 ? "left" : "right"}
                  key={index}
                  isDevice={subsection.device}
                />
              ))}
            </div>
            <SubsectionEdit
              side="left"
              type={currentSection.type}
              onAccept={(title, description, image, device) => {
                setCurrentSection({
                  ...currentSection,
                  subsections: [
                    ...currentSection.subsections,
                    { title, description: description, image, device },
                  ],
                });
              }}
              isDeviceChanged={isDeviceChanged}
            />
          </div>
          <div className="add-element-holder">
            <a
              className="button button-primary"
              onClick={() => {
                setSections([
                  ...sections,
                  {
                    title: currentSection.title,
                    description: currentSection.description,
                    type: currentSection.type,
                    subsections: currentSection.subsections,
                  },
                ]);
                setCurrentSection({
                  title: "",
                  description: "",
                  type: "",
                  subsections: [],
                });
              }}
            >
              Add Section
            </a>
          </div>

          {<JourneyMap milestones={journeyMap} />}
          <div className="add-element-holder">
            <div className="add-featuret-technology-holder">
              <input
                className="add-project-input"
                type={"text"}
                placeholder={"Add Journey Map"}
                value={currentJourneyMap}
                onChange={(e) => setCurrentJourneyMap(e.target.value)}
              />
            </div>
            <a
              className="button button-primary"
              onClick={() => {
                setJourneyMap([...journeyMap, { title: currentJourneyMap }]);
                setCurrentJourneyMap("");
              }}
            >
              +
            </a>
          </div>
          {<Technologies technologies={technologies} />}

          <div>
            <div class="tagcloud01">
              <ul>
                {technologiesEnum.map((item) => (
                  <li
                    key={item.value}
                    onClick={() => {
                      if (technologies.includes(item.value)) {
                        setTechnologies(
                          technologies.filter((i) => i !== item.value)
                        );
                      } else {
                        setTechnologies([...technologies, item.value]);
                      }
                    }}
                  >
                    <a
                      className={
                        technologies.includes(item.value) ? "tag-selected" : ""
                      }
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        style={{ position: "fixed ", right: "20px", bottom: "20px" }}
        onClick={openModal}
      >
        <MdOutlineDone />
      </Fab>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeModal}
          onSuccess={closeModal}
          isCancellable={false}
        />
      </Modal>
    </section>
  );
};

const JourneyMap = ({ milestones }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("journey_map")}</p>
          <p className="hero-secondary-body">{t("journey_map_body")}</p>
        </div>
        <CardsHolder elements={milestones} autoImage={true} />
      </div>
    </div>
  );
};

const Technologies = ({ technologies }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("technologies")}</p>
          <p className="hero-secondary-body">{t("technologies_body")}</p>
        </div>
        <TechnologiesLogos elements={technologies} />
      </div>
    </div>
  );
};

export default AddProject;
