import mobile from "../images/services-logos/mobile.svg";
import web from "../images/services-logos/web.svg";
import desktop from "../images/services-logos/desktop.svg";
import seo from "../images/services-logos/seo.svg";
import helpdesk from "../images/services-logos/help-desk.svg";
import testing from "../images/services-logos/testing.svg";
import uxui from "../images/services-logos/ux-ui.svg";
import embedded from "../images/services-logos/embedded-systems.svg";

export const services = [
  {
    index: 1,
    title: "Mobile",
    name: "mobile_app_development",
    image: mobile,
  },
  {
    index: 2,
    title: "Web",
    name: "web_app_development",
    image: web,
  },
  {
    index: 0,
    title: "Desktop",
    name: "desktop_app_development",
    image: desktop,
  },
  {
    index: 3,
    title: "SEO",
    name: "seo",
    image: seo,
  },
  {
    index: 4,
    title: "Help Desk",
    name: "helpdesk",
    image: helpdesk,
  },
  {
    index: 5,
    title: "Testing",
    name: "testing",
    image: testing,
  },
  {
    index: 6,
    title: "UX/UI",
    name: "uiux",
    image: uxui,
  },
  {
    index: 7,
    title: "Embedded Systems",
    name: "embedded_systems",
    image: embedded,
  },
];
