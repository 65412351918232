import React from "react";
import ok from "../../../assets/images/map-image/modal-icons/ok.svg";
import done from "../../../assets/images/map-image/modal-icons/done.svg";
import broadcast from "../../../assets/images/map-image/modal-icons/broadcast.svg";
import cancel from "../../../assets/images/map-image/modal-icons/cancel.svg";
import congrats from "../../../assets/images/map-image/modal-icons/congrats.svg";
import message from "../../../assets/images/map-image/modal-icons/message.svg";
import block from "../../../assets/images/map-image/modal-icons/block.svg";
import no from "../../../assets/images/map-image/modal-icons/no.svg";
import dislike from "../../../assets/images/map-image/modal-icons/dislike.svg";
import like from "../../../assets/images/map-image/modal-icons/like.svg";
import close from "../../../assets/images/map-image/modal-icons/close-button.svg";
import { useTranslation } from "react-i18next";

const ActionModal = ({
  title,
  body,
  type,
  onCancel,
  onSuccess,
  isCancellable,
}) => {
  const { t } = useTranslation();

  const renderImage = (type) => {
    switch (type) {
      case "ok":
        return ok;
      case "done":
        return done;
      case "broadcast":
        return broadcast;
      case "cancel":
        return cancel;
      case "congrats":
        return congrats;
      case "message":
        return message;
      case "block":
        return block;
      case "no":
        return no;
      case "dislike":
        return dislike;
      case "like":
        return like;
      case "close":
        return close;
      default:
        return ok;
    }
  };

  return (
    <div className={"modal-holder"}>
      <img
        className={"modal-close-button"}
        src={close}
        alt="modal"
        width={"30x"}
        height={"30px"}
        onClick={onCancel}
      ></img>
      <img
        src={renderImage(type)}
        alt="modal"
        className={"modal-image"}
        width={"60x"}
        height={"60px"}
      />
      <p className="modal-title">{title}</p>
      <p className="modal-body">{body}</p>
      <div className={"modal-buttons-holder"}>
        {isCancellable && (
          <a className={"modal-cancel-button"} onClick={onCancel}>
            {t("cancel")}
          </a>
        )}
        <a className={"modal-success-button"} onClick={onSuccess}>
          {t("okay")}
        </a>
      </div>
    </div>
  );
};

export default ActionModal;
