import React from "react";
import subscribeIcon from "../../../assets/images/map-image/subscribe-icon.svg";
import Modal from "react-modal";
import ActionModal from "../../layout/partials/ActionModal";
import { useTranslation } from "react-i18next";
import { addEmailToNewsletter } from "../../../actions";

const Subscribe = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });

  function openModal() {
    addEmailToNewsletter(email)
      .then((res) => {
        setModalContent({
          type: "congrats",
          title: t("subscription_sent_successfully"),
          body: t("subscription_sent_successfully_description"),
        });
        setIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: "block",
          title: t("message_sent_error"),
          body: t("message_sent_error_description"),
        });
        setIsOpen(true);
      });
    // setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
    },
  };

  return (
    <div className={"container subscribe-background"}>
      <div className="subscribe-icon-holder">
        <img
          className="subscribe-icon"
          src={subscribeIcon}
          alt="subscribe-icon"
        />
      </div>
      <div className={"subscribe-body"}>
        <p className="subscribe-title">{t("subscribe_body")}</p>
        <div className="input-holder">
          <input
            className="email-input"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <a className="button button-primary send-button" onClick={openModal}>
            {t("send")}
          </a>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeModal}
          onSuccess={closeModal}
          isCancellable={false}
        />
      </Modal>
    </div>
  );
};

export default Subscribe;
