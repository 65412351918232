import React from "react";
import "html5-device-mockups/dist/device-mockups.min.css";

const renderType = (type) => {
  switch (type) {
    case "ios":
      return {
        margin: "-40px",
        name: "iPhone7",
        width: "180px",
      };

    case "android":
      return {
        margin: "-40px",
        name: "Pixel",
        width: "180px",
      };

    case "mac":
      return {
        margin: "30px",
        name: "Macbook2015",
        width: "430px",
      };
    case "desktop":
      return {
        margin: "20px",
        name: "SurfaceStudio",
        width: "300px",
      };
    case "tablet":
      return {
        margin: "-40px",
        name: "iPadPro",
        width: "230px",
      };
    default:
      return {
        margin: "20px",
        name: "SurfaceStudio",
        width: "300px",
      };
  }
};

const SubsectionEdit = ({ onAccept, side, type, isDeviceChanged }) => {
  let [title, setTitle] = React.useState("");
  let [description, setDescription] = React.useState("");
  let [image, setImage] = React.useState("");
  let [isDevice, setIsDevice] = React.useState(false);

  let device = renderType(type);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        margin: "20px",
      }}
    >
      <div
        className={
          side === "left"
            ? "container project-section-background-left"
            : "container project-section-background-right"
        }
      >
        <div className="project-section-holder">
          {side === "left" && (
            <div className="project-section-image-holder">
              <div className={"project-section-image-left"}>
                {!isDevice ? (
                  <div
                    className="device-holder"
                    style={{ marginTop: device.margin }}
                  >
                    <div
                      className="device-wrapper"
                      style={{ maxWidth: device.width }}
                    >
                      <div
                        className="device"
                        data-device={device.name}
                        data-orientation="portrait"
                        data-color="black"
                      >
                        <div className="screen">
                          <img
                            src={`data:image/png;base64, ${image}`}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            alt="device"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={`data:image/png;base64, ${image}`}
                    style={{
                      height: "100%",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                    alt="device"
                  />
                )}
              </div>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ marginTop: "20px" }}
                onChange={(file) => {
                  getBase64(file.target.files[0]).then((base64) => {
                    setImage(base64);
                  });
                }}
              />
            </div>
          )}
          <div className={"project-section-body"}>
            <input
              type="text"
              placeholder="Title"
              className="project-section-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              placeholder="Description"
              className="project-section-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="project-section-is-device">
              <p className="project-section-is-device-label">Is Device</p>
              <input
                type="checkbox"
                checked={isDevice}
                label="Is Device"
                onChange={(e) => {
                  setIsDevice(e.target.checked);
                  isDeviceChanged(e.target.checked);
                }}
              />
            </div>
          </div>
          {side === "right" && (
            <div className="project-section-image-holder">
              <div className={"project-section-image-right"}>
                <div
                  className="device-holder"
                  style={{ marginTop: device.margin }}
                >
                  <div
                    className="device-wrapper"
                    style={{ maxWidth: device.width }}
                  >
                    <div
                      className="device"
                      data-device={device.name}
                      data-orientation="portrait"
                      data-color="black"
                    >
                      <div className="screen">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url('${image}')`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      <a
        className="button button-primary"
        onClick={() => {
          onAccept(title, description, image, isDevice);
          setTitle("");
          setDescription("");
          setImage("");
        }}
      >
        Add Subsection
      </a>
    </div>
  );
};

export default SubsectionEdit;
