import React from "react";

const ServiceAboutHolder = ({ title, body, image, side }) => {
  return (
    <div
      className={
        side === "left"
          ? "container about-background-left"
          : "container about-background-right"
      }
    >
      <div className="about-holder">
        {side === "left" && (
          <div className="about-image-holder">
            <div
              className={"about-image-left"}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          </div>
        )}
        <div className={"about-body"}>
          <p className="about-title">{title}</p>
          <p className="about-description">{body}</p>
        </div>
        {side === "right" && (
          <div className="about-image-holder">
            <div
              className={"about-image-right"}
              style={{ backgroundImage: `url(${image})` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceAboutHolder;
