export const infographics = [
  {
    id: 0,
    number: "6+",
    text: "Years of experience",
  },
  {
    id: 1,
    number: "25+",
    text: "Successful Projects",
  },
  {
    id: 2,
    number: "5+",
    text: " Countries",
  },
];
