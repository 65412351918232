import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import Subscribe from "../../components/layout/partials/Subscribe";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import {
  embeddedApproach,
  embeddedDetails,
  embeddedIndustries,
  embeddedSystemsAndTechnologies,
  mobileDetails,
} from "../../assets/static/card-details";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import embedded from "../../assets/images/about-images/embedded.png";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const EmbeddedSystemsService = () => {
  const { t } = useTranslation();
  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("embedded_systems")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("embedded_systems_title")}
              <span className="hero-secondary-title-blue">
                {t("embedded_systems_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">{t("embedded_systems_body")}</p>
          </div>
        </div>
      </div>

      <br />
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("embedded_systems_paragraph_4_title")}
          </p>
        </div>
      </div>
      <CardsHolder elements={embeddedApproach} />
      <br />

      <ServiceAboutHolder
        title={t("embedded_systems_paragraph_1_title")}
        body={t("embedded_systems_paragraph_1_body")}
        image={embedded}
        side={"left"}
      />

      <br />

      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("embedded_systems_paragraph_2_title")}
          </p>
        </div>
      </div>
      <CardsHolder elements={embeddedDetails} autoImage={true} />
      <br />
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("embedded_systems_paragraph_3_title")}
          </p>
        </div>
      </div>
      <CardsHolder elements={embeddedSystemsAndTechnologies} />

      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default EmbeddedSystemsService;
