import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import PortfolioCard from "../components/layout/partials/PortfolioCard";
import { getProjectHeaders } from "../actions";
import { useSelector } from "react-redux";
import ScrollToTop from "../utils/ScrollToTop";
import { Fab } from "@mui/material";
import { FiDownloadCloud } from "react-icons/fi";

const Portfolios = () => {
  const { t } = useTranslation();

  let [elements, setElements] = useState([]);
  let navigator = useSelector((state) => state.navigationReducer);

  useEffect(() => {
    getProjectHeaders(navigator.language).then((res) => {
      console.log(res.data);
      setElements(res.data);
    });
  }, []);

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("portfolio")]}
              title={t("portfolio")}
              description={t("portfolios_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("portfolios_paragraph_1_title")}{" "}
              <span className="hero-secondary-title-blue">
                {t("portfolios_paragraph_1_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">
              {t("portfolios_paragraph_1_body")}
            </p>
          </div>
          <div className="contact-holder">
            <PortfolioCard elements={elements} />
          </div>
        </div>
      </div>

      <Fab
        color="primary"
        aria-label="add"
        size="medium"
        style={{ position: "fixed ", right: "100px", bottom: "40px" }}
        variant="extended"
        onClick={() => {
          window
            .open(
              "https://quartztechnologies.s3.eu-west-2.amazonaws.com/project-images/Quartz+Technologies+Portfolio_.pdf",
              "_blank"
            )
            .focus();
        }}
      >
        <FiDownloadCloud sx={{ mr: 1 }} style={{ marginRight: "5px" }} />
        {t("download")}
      </Fab>
    </section>
  );
};

export default Portfolios;
