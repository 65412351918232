import DesktopAppDevelopmentService from "../../layouts/services/DesktopAppDevelopmentService";
import MobileDevelopmentService from "../../layouts/services/MobileDevelopmentService";
import WebDevelopmentService from "../../layouts/services/WebDevelopmentService";
import SEOService from "../../layouts/services/SEOService";
import HelpDeskService from "../../layouts/services/HelpDeskService";
import TestingService from "../../layouts/services/TestingService";
import UIUXService from "../../layouts/services/UXUIService";
import EmbeddedSystemsService from "../../layouts/services/EmbeddedSystemsService";

export const serviceRoutes = [
  {
    name: "desktop_app_development",
    index: 0,
    path: "/services/desktop-app-development",
    component: DesktopAppDevelopmentService,
  },
  {
    name: "mobile_app_development",
    index: 1,
    path: "/services/mobile-app-development",
    component: MobileDevelopmentService,
  },
  {
    name: "web_app_development",
    index: 2,
    path: "/services/web-development",
    component: WebDevelopmentService,
  },
  {
    name: "seo",
    index: 3,
    path: "/services/seo",
    component: SEOService,
  },
  {
    name: "helpdesk",
    index: 4,
    path: "/services/helpdesk",
    component: HelpDeskService,
  },
  {
    name: "testing",
    index: 5,
    path: "/services/testing",
    component: TestingService,
  },
  {
    name: "uiux",
    index: 6,
    path: "/services/uxui-design",
    component: UIUXService,
  },
  {
    name: "embedded_systems",
    index: 7,
    path: "/services/embedded-systems",
    component: EmbeddedSystemsService,
  },
];
