import React from "react";

const AboutDetailsHolder = ({ number, title, body, side }) => {
  return (
    <div className={"about-detail-holder-" + side}>
      <div className={"about-detail-number-holder-" + side}>
        <p className="about-detail-number">{number}</p>
      </div>
      <p className={"about-detail-title-" + side}>{title}</p>
      <p className={"about-detail-body-" + side}>{body}</p>
    </div>
  );
};

export default AboutDetailsHolder;
