import "./App.css";
import React, { useEffect } from "react";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import AppRoute from "./utils/AppRoute";
import { Switch } from "react-router-dom";
import Home from "./layouts/Home";
import Portfolios from "./layouts/Portfolios";
import Contacts from "./layouts/Contacts";
import { serviceRoutes } from "./assets/static/service-routes";
import Services from "./layouts/Services";
import NotFound from "./layouts/NotFound";
import AboutUs from "./layouts/AboutUs";
import { Provider } from "react-redux";
import { store } from "./store";
import ProjectPage from "./layouts/ProjectPage";
import AddProject from "./layouts/AddProject";
import ReactGA from "react-ga";
import PrivacyPolicy from "./layouts/PrivacyPolicy";
import ThankYou from "./layouts/ThankYou";

function App() {
  const TRACKING_ID = "UA-237215541-1";

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={store}>
      <Switch>
        <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
        <AppRoute
          exact
          path="/contacts"
          component={Contacts}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/portfolio"
          component={Portfolios}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/services"
          component={Services}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/about"
          component={AboutUs}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/project/:id"
          component={ProjectPage}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/portfolio/add"
          component={AddProject}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/privacy-policy"
          component={PrivacyPolicy}
          layout={LayoutDefault}
        />
        <AppRoute
          exact
          path="/thanks"
          component={ThankYou}
          layout={LayoutDefault}
        />
        {serviceRoutes.map((route, index) => (
          <AppRoute
            exact
            path={route.path}
            component={route.component}
            layout={LayoutDefault}
            key={index}
          />
        ))}

        <AppRoute exact path="*" component={NotFound} layout={LayoutDefault} />
      </Switch>
    </Provider>
  );
}

export default App;
