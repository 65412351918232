import React from "react";
import Image from "../../elements/Image";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PortfolioCard = ({ elements }) => {
  const { t } = useTranslation();

  return (
    <div className={"container portfolio-cards-holder"}>
      {elements.map((element, index) => {
        return (
          <div className={"portfolio-card"} key={index}>
            <div className="type">
              {element.relatedServices.slice(0, 3).map((service, index) => (
                <p className="type-title" key={index}>
                  &nbsp;{service + (2 !== index ? " | " : "")}
                </p>
              ))}
            </div>
            {index % 2 === 0 ? (
              <div
                className={"image-left"}
                style={{ backgroundImage: `url(${element.backgroundImage})` }}
              />
            ) : (
              <div
                className={"image-right"}
                style={{ backgroundImage: `url(${element.backgroundImage})` }}
              />
            )}
            <p className="project-title">{element.title}</p>
            <p className="project-description">{element.description}</p>
            <Link
              to={`/project/${element.id}`}
              className="button button-more-left"
            >
              <p className="view-more">{t("view_project")}</p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default PortfolioCard;
