import React from "react";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import testing from "../../assets/images/about-images/testing.png";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import { testingDetails } from "../../assets/static/card-details";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const TestingService = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("testing")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("testing_title") + " "}
              <span className="hero-secondary-title-blue">
                {t("testing_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">{t("testing_body")}</p>
          </div>
        </div>
      </div>

      <ServiceAboutHolder
        title={t("testing_paragraph_1_title")}
        body={t("testing_paragraph_1_body")}
        image={testing}
        side={"left"}
      />

      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("testing_paragraph_2_title")}
          </p>
        </div>
      </div>
      <CardsHolder elements={testingDetails} />

      <div>
        <div className="hero-secondary">
          {/* <div>
            <div className="hero-secondary secondary-dark">
              <p className="hero-secondary-title">
                {t("helpdesk_paragraph_3_title") + " "}
                <span className="hero-secondary-title-blue">
                  {t("helpdesk_title_blue")}
                </span>
              </p>
              <p className="hero-secondary-body">
                {t("helpdesk_paragraph_3_body")}
              </p>
            </div>
          </div> */}

          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        {/* <Examples elements={examples} rows={1} /> */}
      </div>
    </section>
  );
};

export default TestingService;
