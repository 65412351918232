import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/LogoFull";
import FooterNav from "./partials/FooterNav";
import { Link } from "react-router-dom";
import FooterSocial from "./partials/FooterSocial";
import { useTranslation } from "react-i18next";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  const { t } = useTranslation();

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xxs footer-align">
            <div style={{ flex: 1 }}>
              <Logo />
            </div>
            <div
              className="footer-bottom space-between text-xxs invert-order-desktop"
              style={{ flex: 2 }}
            >
              <FooterNav />
            </div>
            <div
              className="footer-top space-between-get-in-touch text-xxs"
              style={{ flex: 1 }}
            >
              <Link
                to="#0"
                className="button button-wide-mobile button-sm button-get-in-touch"
                // onClick={closeMenu}
              >
                <p className="get-in-touch">{t("get_in_touch")}</p>
              </Link>
              <FooterSocial />
            </div>
          </div>
          <div className="footer-copyright space-between text-xxxs invert-order-desktop">
            <p>{t("all_rights_reserved")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
