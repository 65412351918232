import React from "react";

import NavigationHeader from "../../components/layout/partials/NavigationHeader";
import ServiceAboutHolder from "../../components/layout/partials/ServiceAboutHolder";
import mobile from "../../assets/images/about-images/mobile.png";
import mobile1 from "../../assets/images/about-images/mobile1.png";
import mobile2 from "../../assets/images/about-images/mobile2.jpg";
import Examples from "../../components/layout/Examples";
import { examples } from "../../assets/static/examples";
import Subscribe from "../../components/layout/partials/Subscribe";
import CardsHolder from "../../components/layout/partials/CardsHolder";
import { mobileDetails } from "../../assets/static/card-details";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../utils/ScrollToTop";

const MobileDevelopmentService = () => {
  const { t } = useTranslation();
  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("services")]}
              title={t("mobile_app_development")}
              description={t("services_description")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <p className="hero-secondary-title">
              {t("mobile_app_development_title") + " "}
              <span className="hero-secondary-title-blue">
                {t("mobile_app_development_title_blue")}
              </span>
            </p>
            <p className="hero-secondary-body">
              {t("mobile_app_development_body")}
            </p>
          </div>
        </div>
      </div>

      <ServiceAboutHolder
        title={t("mobile_app_development_paragraph_1_title")}
        body={t("mobile_app_development_paragraph_1_body")}
        image={mobile}
        side={"left"}
      />

      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">
            {t("mobile_app_development_paragraph_2_title") + " "}
            <span className="hero-secondary-title-blue">
              {t("mobile_app_development_paragraph_2_title_blue")}
            </span>
          </p>
        </div>

        <CardsHolder elements={mobileDetails} />
      </div>

      <ServiceAboutHolder
        title={t("mobile_app_development_paragraph_3_title")}
        body={t("mobile_app_development_paragraph_3_body")}
        image={mobile1}
        side={"right"}
      />

      <ServiceAboutHolder
        title={t("mobile_app_development_paragraph_4_title")}
        body={t("mobile_app_development_paragraph_4_body")}
        image={mobile2}
        side={"left"}
      />
      <div>
        <div className="hero-secondary">
          <Subscribe />
        </div>
      </div>

      {/* <div>
        <div className="hero-secondary">
          <p className="hero-secondary-title">{t("our_works")}</p>
          <p className="hero-secondary-body">{t("our_works_description")}</p>
        </div>
        <Examples elements={examples} rows={1} />
      </div> */}
    </section>
  );
};

export default MobileDevelopmentService;
