import Services from "../../components/layout/Services";
import Contacts from "../../layouts/Contacts";
import Home from "../../layouts/Home";
import Portfolios from "../../layouts/Portfolios";

export const routes = [
  {
    title: "Home",
    index: 0,
    path: "/",
    name: "home",
    component: Home,
  },
  // {
  //   title: "Portfolio",
  //   index: 1,
  //   path: "/portfolio",
  //   name: "portfolio",
  //   component: Portfolios,
  // },
  {
    title: "Services",
    index: 2,
    path: "/services",
    name: "services",
    component: Services,
  },
  {
    title: "Contacts",
    index: 3,
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    title: "About",
    index: 4,
    path: "/about",
    name: "about",
    component: Contacts,
  },
];
