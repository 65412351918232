import React from "react";
import classNames from "classnames";
import { routes } from "../../../assets/static/router";
import { Link } from "react-router-dom";
import { serviceRoutes } from "../../../assets/static/service-routes";
import { useTranslation } from "react-i18next";

const HeaderNav = ({ className, onClick }) => {
  let [hovered, setHovered] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      {/* <div className="underline"></div> */}

      {routes.map((route, index) => (
        <Link
          to={route.path}
          onClick={(e) => {
            onClick(route.index);
            console.log(e.target);
          }}
          className="menu-item-link"
        >
          <div
            className={classNames("a-header-nav nav-service", className)}
            onMouseEnter={() => route.index === 2 && setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            key={index}
          >
            {t(route.name)}

            {hovered && route.index === 2 && (
              <div className="dropdown">
                <div className="notch"></div>
                {serviceRoutes.map((route, index) => (
                  <Link
                    className="dropdown-element"
                    key={index}
                    to={route.path}
                  >
                    {t(route.name)}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </Link>
      ))}
    </>
  );
};

export default HeaderNav;
