import React from "react";
import { Link } from "react-router-dom";
import { serviceRoutes } from "../../assets/static/service-routes";
import Image from "../elements/Image";
import { useTranslation } from "react-i18next";

const Services = ({ elements }) => {
  const { t } = useTranslation();

  return (
    <div className={"container services-holder"}>
      {elements.map((element, index) => {
        return (
          <Link
            to={
              serviceRoutes.filter((route) => route.index === element.index)[0]
                .path
            }
            className={"service-card"}
            key={index}
          >
            <Image
              className={"service-image"}
              src={element.image}
              width={64}
              height={64}
            />
            <p className="service-title">{t(element.name)}</p>
            <p className="service-description">
              {t(element.name + "_description")}
            </p>
          </Link>
        );
      })}
    </div>
  );
};

export default Services;
